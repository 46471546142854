import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row, Breadcrumb } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../layout/Layout";
import Switch from "react-switch";
import { toast } from "react-toastify";
import { StampAdd, StampCategoryDropdown } from "../../Auth/Api";
import { SelectPicker } from "rsuite";
import { DateRangePicker } from "react-bootstrap-daterangepicker";
import moment from "moment"

const StampsAdd = () => {
    const Redirect = useNavigate();
    const [loading, Setloading] = useState(false);
    const [validate, setValidate] = useState(false);
    const [categoryDropdownArray, setCategoryDropdownArray] = useState([]);
    const [selectedCategoryError, setSelectedCategoryError] = useState(false); // New state for validation
    const [isNotification, setIsNotification] = useState(0)

    const [Data, SetData] = useState({
        main_image: "",
        zip_name: "",
        zip_name_ios: "",
        is_premium: 0,
        is_premium_ios: 0,
        status: 0,
        status_ios: 0,
        stamp_image: "",
        stamp_background_image: "",
        category_id: "",
        // is_new: 0,
        tansparent_image: "",
        date: "",
        time: "",
        title:"",
        content: "",
        notification_image:""
    });
    // console.log('Data -->', Data)
    const InputFile = (e) => {
        SetData({ ...Data, [e.target.name]: e.target.files[0] });
        setValidate(false);
    };
    const categoryDropdown = async () => {
        const Result = await StampCategoryDropdown();
        setCategoryDropdownArray(
            Result?.data?.Data.map((val, index) => {
                return { label: val.name, value: val._id };
            })
        );
    };
    useEffect(() => {
        categoryDropdown();
    }, []);
    const handleOnchageSelect = (e) => {
        SetData({ ...Data, category_id: e });
        setValidate(false);
        setSelectedCategoryError(false); // R
    };
    const Save = async (e) => {
        
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === true && Data.category_id !== "") {
            Setloading(true);
            e.stopPropagation();
            //setValidate(true);
            // console.log('Data', Data)
            const Result = await StampAdd(Data);
            if (Result.data.Status === true) {
                toast.success("Data Saved Successfully");
                Setloading(false);
                Redirect("/Stamps");
            } else {
                toast.error(Result.data.Response_Message);
                Setloading(false);
            }
        } else {
            setValidate(true);
            setSelectedCategoryError(Data.category_id === "");
            // Setloading(false)  MVC architechture for large project nodejs 
        }
    };
    // console.log('isNotification', isNotification)

    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3>Stamps Add</h3>
                <Breadcrumb className="d-none d-sm-none d-md-none d-lg-block">
                    <Breadcrumb.Item>
                        <Link to="/Home">
                            <i className="bx bx-home-alt me-2 fs-5"></i> Home
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/Stamps">Stamps</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Stamps Add</Breadcrumb.Item>
                </Breadcrumb>
            </div>

            <div className="page-content">
                <Form noValidate validated={validate} onSubmit={Save}>
                    <Card className="mb-4">
                        <Card.Body>
                            {loading === true ? <div className="loader"></div> : <></>}
                            <Row className="mb-2">
                                <Col md={6}>
                                    <Form.Label>Category</Form.Label>
                                    <SelectPicker
                                        data={categoryDropdownArray}
                                        cleanable={false}
                                        onChange={(e) => handleOnchageSelect(e)}
                                        value={Data.category_id}
                                        block
                                        className={`my-2 ${selectedCategoryError ? "is-invalid" : ""}`} // Apply is-invalid class if there is an error
                                        placeholder="Select Category"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Category Field Is Required
                                    </Form.Control.Feedback>
                                </Col>
                                <Col md={6}>
                                    <Form.Label>Thumb Image</Form.Label>
                                    <Form.Control
                                        type="file"
                                        name="main_image"
                                        className="my-2"
                                        onChange={(e) => {
                                            InputFile(e);
                                        }}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Thumb Image Field Is Require
                                    </Form.Control.Feedback>
                                </Col>
                                <Col md={6}>
                                    <Form.Label>Image</Form.Label>
                                    <Form.Control
                                        type="file"
                                        name="stamp_image"
                                        className="my-2"
                                        onChange={(e) => {
                                            InputFile(e);
                                        }}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Stamp Image Field Is Require
                                    </Form.Control.Feedback>
                                </Col>
                                <Col md={6}>
                                    <Form.Label>Background Image</Form.Label>
                                    <Form.Control
                                        type="file"
                                        name="stamp_background_image"
                                        className="my-2"
                                        onChange={(e) => {
                                            InputFile(e);
                                        }}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Stamp Bacekground Image Field Is Require
                                    </Form.Control.Feedback>
                                </Col>
                                <Col md={6}>
                                    <Form.Label>Transparent Image</Form.Label>
                                    <Form.Control
                                        type="file"
                                        name="tansparent_image"
                                        className="my-2"
                                        onChange={(e) => {
                                            InputFile(e);
                                        }}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Stam Tpransparent Image Field Is Require
                                    </Form.Control.Feedback>
                                </Col>
                                <Col md={6}>
                                    <Form.Label>ZIP Name</Form.Label>
                                    <Form.Control
                                        type="file"
                                        name="zip_name"
                                        accept=".zip"
                                        className="my-2"
                                        onChange={(e) => { InputFile(e) }}
                                        required={Data.zip_name ? true : false}
                                    />
                                    <Form.Control.Feedback type="invalid">ZIP Name Field Is Required</Form.Control.Feedback>
                                </Col>
                                <Col md={6}>
                                    <Form.Label>ZIP Name iOS</Form.Label>
                                    <Form.Control
                                        type="file"
                                        name="zip_name_ios"
                                        accept=".zip"
                                        className="my-2"
                                        onChange={(e) => {
                                            console.log(e)
                                            InputFile(e);
                                        }}
                                        required={Data.zip_name_ios ? true : false}
                                    />
                                </Col>
                                <Col md={3}>
                                    <Form.Label className="d-block mb-2">Is Premium</Form.Label>
                                    <Switch
                                        onChange={(e) => {
                                            SetData({ ...Data, is_premium: e === true ? 1 : 0 });
                                        }}
                                        checked={Data.is_premium === 1 ? true : false}
                                        offColor="#C8C8C8"
                                        onColor="#0093ed"
                                        height={30}
                                        width={70}
                                        className="react-switch"
                                        uncheckedIcon={<div className="react-switch-off">NO</div>}
                                        checkedIcon={<div className="react-switch-on">YES</div>}
                                    />
                                </Col>
                                <Col md={3}>
                                    <Form.Label className="d-block mb-2">
                                        Is Premium iOS
                                    </Form.Label>
                                    <Switch
                                        onChange={(e) => {
                                            SetData({ ...Data, is_premium_ios: e === true ? 1 : 0 });
                                        }}
                                        checked={Data.is_premium_ios === 1 ? true : false}
                                        offColor="#C8C8C8"
                                        onColor="#0093ed"
                                        height={30}
                                        width={70}
                                        className="react-switch"
                                        uncheckedIcon={<div className="react-switch-off">NO</div>}
                                        checkedIcon={<div className="react-switch-on">YES</div>}
                                    />
                                </Col>
                                <Col md={3}>
                                    <Form.Label className="d-block mb-2">Status</Form.Label>
                                    <Switch
                                        onChange={(e) => {
                                            SetData({ ...Data, status: e === true ? 1 : 0 });
                                        }}
                                        checked={Data.status === 1 ? true : false}
                                        offColor="#C8C8C8"
                                        onColor="#0093ed"
                                        height={30}
                                        width={70}
                                        className="react-switch"
                                        uncheckedIcon={<div className="react-switch-off">OFF</div>}
                                        checkedIcon={<div className="react-switch-on">ON</div>}
                                    />
                                </Col>
                                <Col md={3}>
                                    <Form.Label className="d-block mb-2">Status iOS</Form.Label>
                                    <Switch
                                        onChange={(e) => {
                                            SetData({ ...Data, status_ios: e === true ? 1 : 0 });
                                        }}
                                        checked={Data.status_ios === 1 ? true : false}
                                        offColor="#C8C8C8"
                                        onColor="#0093ed"
                                        height={30}
                                        width={70}
                                        className="react-switch"
                                        uncheckedIcon={<div className="react-switch-off">OFF</div>}
                                        checkedIcon={<div className="react-switch-on">ON</div>}
                                    />
                                </Col>
                                <Col md={3}>
                                    <Form.Label className="d-block mb-2">Is Notification</Form.Label>
                                    <Switch
                                        onChange={(e) => { setIsNotification((e === true) ? 1 : 0) }}
                                        checked={(isNotification === 1) ? true : false}
                                        offColor="#C8C8C8"
                                        onColor="#0093ed"
                                        height={30}
                                        width={70}
                                        className="react-switch"
                                        uncheckedIcon={
                                            <div className="react-switch-off">No</div>
                                        }
                                        checkedIcon={
                                            <div className="react-switch-on">Yes</div>
                                        }
                                    />
                                </Col>
                            </Row>
                            {isNotification ? <Row>
                                <Col md={4}>
                                    <Form.Label className="d-block mb-2">Date</Form.Label>
                                    <input 
                                    type="date"
                                     className="form-control col-4 my-2" 
                                     
                                     min={new Date().toISOString().split('T')[0]}
                                     onChange={(e) => SetData((prev) => ({ ...prev, date: e.target.value }))}
                                     value={Data.date }
                                     required = {isNotification ? true :false}  
                                    />
                                  <Form.Control.Feedback type="invalid">
                                       Invalid Date
                                    </Form.Control.Feedback>
                                </Col>
                                <Col md={4}>
                                    <Form.Label className="d-block mb-2">Time</Form.Label>
                                    <input 
                                    type="time" 
                                    min={moment(Data.date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') && moment(Data.time, 'HH:mm').format('HH:mm') < moment().format('HH:mm') ? moment().format('HH:mm') : ""}
                                    onChange={(e) => SetData((prev) => ({ ...prev, time: e.target.value }))}
                                    value={Data.time}
                                    className="form-control col-4 my-2" 
                                    //  required = {isNotification ? true :false}
                                      />
                                    {/* <Form.Control.Feedback type="invalid">
                                        Time Is Require
                                    </Form.Control.Feedback> */}
                                </Col>
                                <Col md={4}>
                                    <Form.Label className="d-block mb-2">Image</Form.Label>
                                     <Form.Control 
                                     type="file"
                                     name="notification_image"
                                     onChange={(e) => {
                                        InputFile(e);
                                    }}
                                      className="form-control col-4 my-2"
                                    //   required = {isNotification ? true :false}   
                                     />
                                     {/* <Form.Control.Feedback type="invalid">
                                        Notification Image Field Is Require
                                    </Form.Control.Feedback> */}
                                </Col>
                                <Col md={6}>
                                    <Form.Label className="d-block mb-2">Title</Form.Label>
                                    <Form.Control
                                     type="text" 
                                     rows={3} 
                                     maxLength={33}
                                     className="my-2"  
                                     onChange={(e) => SetData((prev) => ({ ...prev, title: e.target.value }))}
                                      required = {isNotification ? true :false}/>
                                    <Form.Control.Feedback type="invalid">
                                      Title Is Require
                                    </Form.Control.Feedback>
                                </Col>
                                <Col md={6}>
                                    <Form.Label className="d-block mb-2">Content</Form.Label>
                                    <Form.Control
                                     as="textarea" 
                                     rows={2} 
                                     maxLength={133}
                                     className="my-2"  
                                     onChange={(e) => SetData((prev) => ({ ...prev, content: e.target.value }))}
                                      required = {isNotification ? true :false}/>
                                    <Form.Control.Feedback type="invalid">
                                        Content Is Require
                                    </Form.Control.Feedback>
                                </Col>
                               
                            </Row> : <></>}
                        </Card.Body>
                        <Card.Footer className="text-end">
                            <Button variant="primary" className="me-3" type="submit">
                                Save
                            </Button>
                            <Link to="/Stamps" className="btn btn-secondary">
                                Cancel
                            </Link>
                        </Card.Footer>
                    </Card>
                </Form>
            </div>
        </Layout>
    );
};

export default StampsAdd;
